<template>
  <df-modal
    persistent
    :action-name="$t('SowingsPlanning.add_date_sowing_modal.add')"
    :loading="loadingContent"
    :title="$t('SowingsPlanning.add_date_sowing_modal.new_sowing_date')"
    @action-click="addSowing"
    @close="closeAddDateSowingModal"
  >
    <template #card-content>
      <v-form ref="addDateSowingForm">
        <df-date-picker
          v-model="sowingEstimatedDate"
          :hint="
            $t('SowingsPlanning.date_picker_component.estimated_sowing_hint')
          "
          :label="
            $t('SowingsPlanning.date_picker_component.estimated_sowing_date')
          "
          :placeholder="$t('SowingsPlanning.date_picker_component.select_date')"
          :rules="[required]"
        />
      </v-form>
    </template>
  </df-modal>
</template>

<script>
import DfDatePicker from '@/lib/components/DatePicker/DfDatePicker.vue'
import DfModal from '@/lib/Modal/DfModal.vue'
import sowingsPlanningService from '@/modules/sowingsPlanning/services/sowingsPlanningService.js'
import { required } from '@/utils/formRules'
import { logEvent, events } from '@/services/analytics'

export default {
  name: 'AddDateSowingModal',

  components: {
    DfDatePicker,
    DfModal,
  },

  props: {
    variety: {
      required: true,
      type: Object,
    },
  },

  data() {
    return {
      loadingContent: false,
      required,
      sowingEstimatedDate: '',
    }
  },

  inject: {
    requestFieldSowingsPlanning: {
      from: 'requestFieldSowingsPlanning',
    },
  },

  methods: {
    async addSowing() {
      if (!this.$refs.addDateSowingForm.validate()) return
      logEvent(
        events.sowingsPlanningModule.SowingsPlanning.finish_add_new_sowing_date
      )
      this.loadingContent = true
      try {
        const params = {
          is_planning: true,
          sowing_date: this.sowingEstimatedDate,
          sowing_id: this.variety.sowings[0].id,
        }
        await sowingsPlanningService.copySowingPlanning(params)
        this.$root.$emit(
          'notify',
          'success',
          this.$t(
            'SowingsPlanning.add_date_sowing_modal.successful_request_title'
          )
        )
        this.requestFieldSowingsPlanning()
        this.closeAddDateSowingModal()
      } catch (error) {
        console.error(error)
        this.$root.$emit(
          'notify',
          'error',
          this.$t('exception.unexpected_error_title'),
          this.$t('SowingsPlanning.exceptions.add_sowing')
        )
      } finally {
        this.loadingContent = false
      }
    },
    closeAddDateSowingModal() {
      this.$emit('close')
    },
  },
}
</script>
